import React from 'react';
import { TailSpin } from 'react-loader-spinner';

const Loader = ({ height = "60px", width = "60px", color = "var(--color-primary)", radius = "1", strokeWidth = 4 }) => {
	return (
		<div className="spin-body">
			<TailSpin
				height={height}
				width={width}
				color={color}
				ariaLabel="tail-spin-loading"
				radius={radius}
				strokeWidth={strokeWidth}
				visible={true}
			/>
		</div>
	);
}
export default Loader;