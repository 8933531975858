import { BASE_URL } from '../config';
import axiosAuthMiddleware from '../middleware/middleware';
import axios from 'axios';
import { get_method, post_method, patch_method, delete_method } from "../utils.js";



export const addNewFarm = async (data) => {
  const url = `/create_farm`;
  return await post_method(url, data);
};

export const getAllUserList = async () => {
  const url = `/all_user_list`;
  return await get_method(url);
};

export const updateFarmForm = async (data) => {
  const url = `/update_farm_data`;
  return await patch_method(url, data);
};

export const getDataFromUniqueLink = async (id) => {
  const url = `/unique_link_farm_data?unique_link=${id}`;
  return await get_method(url);
};
export const getPresignedURL = async (s3_object) => {
  const url = `/get_presigned?s3_object=${s3_object}`;
  return await get_method(url);
};
export const getDocumentList = async (id) => {
  const res = axios.get(`${BASE_URL}/document_list?unique_key=${id}`);
  return await res;
};
export const uploadDocument = async (data) => {
  const res = axios.post(`${BASE_URL}/upload_document`, data);
  return await res;
};

export const deleteDocument = async (data) => {
  const url = `/delete_document`;

  const res = axiosAuthMiddleware.request({
    url: url,
    method: 'DELETE',
    headers: {
      'Content-Type': 'text/plain',
    },
    data: data,
  });
  return await res;
};
export const getOwnerEmail = async (id) => {
  const res = axios.get(`${BASE_URL}/farm_owner_email?unique_key=${id}`);
  return await res;
};
export const checkPasscode = async (data) => {
  const res = axios.post(`${BASE_URL}/check_passcode`, data);
  return await res;
};
export const forgetPasscode = async (data) => {
  const res = axios.post(`${BASE_URL}/forgot_passcode`, data);
  return await res;
};

export const CheckToken = async (token) => {
  const url = `/check_token?token=${token}`;

  const res = axiosAuthMiddleware.request({
    url: url,
    method: 'GET',
    headers: {
      'Content-Type': 'text/plain',
    },
  });
  return await res;
};
export const approveStatus = async (data) => {
  const url = `/approved_farm_form`;

  const res = axiosAuthMiddleware.request({
    url: url,
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
    },
    data: data,
  });
  return await res;
};

export const rejectFarm = async (data) => {
  const url = `/rejected_farm_form`;

  const res = axiosAuthMiddleware.request({
    url: url,
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
    },
    data: data,
  });
  return await res;
};

export const deleteFarm = async (data) => {
  const url = `/delete_farm`;

  const res = axiosAuthMiddleware.request({
    url: url,
    method: 'POST',
    headers: {
      'Content-Type': 'text/plain',
    },
    data: data,
  });
  return await res;
};
