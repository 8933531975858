import React, { useEffect, useRef, useState } from "react";
import { useOnClickOutside } from "../hooks/useOnClickOutside";
// import AddEmailModal from '../AddEmailModal';
import Add from "../../images/Facilitator/AddIcon.svg";
import Bottom from "../../images/Sidebar/Vector (1).svg";
import Search from "../../images/Facilitator/SearchImg.svg";
import Cancel from "../../images/Facilitator/cancel.svg";
import "./AddEmailChip.css";
import AddEmailChipModal from "./AddEmailChipModal";

const SelectEmail = ({
  isOpenUp,
  checked,
  setChecked,
  allOptionsEmail,
  setAllEmailOptions,
  isChipEmailModal = true,
  setEmails,
  isError,
  setMilkSoldList,
  disable,
}) => {
  const [isOpenMenu, setisOpenMenu] = useState(false);
  const [isAddEmailModal, setisAddEmailModal] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [filteredEmailOptions, setFilteredEmailOptions] = useState();
  const [isChipEmailModalOpen, setIsChipEmailModalOpen] = useState(false);
  const [showAllEmail, setShowAllEmail] = useState(false); // Track whether to show all email addresses or "2 more"
  const emailRef = useRef();
  const [newtags, setNewTags] = useState([]);

  useOnClickOutside(!isChipEmailModalOpen && emailRef, () => {
    setisOpenMenu(false);
    setShowAllEmail(false);
  });

  useEffect(() => {
    if (allOptionsEmail) {
      setFilteredEmailOptions(allOptionsEmail);
    }
  }, [allOptionsEmail]);

  const toggleDropdown = () => {
    if (!disable) {
      setisOpenMenu(!isOpenMenu);
      setShowAllEmail(!showAllEmail);
    }
  };

  const handleCheckAllChange = (e, isCheckbox) => {
    if (isCheckbox) {
      if (e.target.checked) {
        const allnumbers = allOptionsEmail?.map((c) => c.name);
        setChecked(allnumbers);
      } else {
        setChecked([]);
      }
    } else {
      if (checked.length !== allOptionsEmail.length) {
        const allnumbers = allOptionsEmail?.map((c) => c.value);
        setChecked(allnumbers);
      } else {
        setChecked([]);
      }
    }
  };
  const handleCountryChange = (e, isCheckbox, phoneNumber) => {
    if (isCheckbox) {
      if (e.target.checked) {
        setChecked([...checked, phoneNumber]);
      } else {
        setChecked(checked?.filter((item) => item !== phoneNumber));
      }
    } else {
      const temp = checked?.filter((item) => item === phoneNumber);
      if (temp?.length > 0) {
        setChecked(checked?.filter((item) => item !== phoneNumber));
      } else {
        setChecked([...checked, phoneNumber]);
      }
    }
  };
  const handleRemoveFromList = (index) => {
    if (!disable) {
      const newArray = Array.from(checked);
      newArray.splice(index, 1);
      setChecked(newArray);
    }
  };
  const openEmailMenu = () => {
    setisOpenMenu(!isOpenMenu);
  };
  const handleAddEmail = () => {
    if (!disable) {
      if (isChipEmailModal) {
        setIsChipEmailModalOpen(true);
      }
    }
  };

  const handleSearchNumber = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchText(searchTerm);
    const filteredOptions = allOptionsEmail.filter((option) =>
      option.name.toLowerCase().includes(searchTerm)
    );
    setFilteredEmailOptions(filteredOptions);
  };

  const AddValuesToList = (newArr,setIsOpen) => {
    const oldValues = Array.from(allOptionsEmail);
    let check = Array.from(checked)
    check.push(...newArr)
    setIsOpen(false);
    newArr.map((value, index) => {
      const obj = {
        id: oldValues.length + index,
        name: value,
      };
      handleCountryChange("e", false, value);
      oldValues.push(obj);
    });
    setChecked(check)
    setMilkSoldList(oldValues);
    setNewTags([]);
  };
  return (
    <>
      <div ref={emailRef} className="input-field-dropdown">
        {checked?.length > 0 ? (
          <div>
            <div
              onClick={toggleDropdown} // Use the toggleDropdown function to toggle the dropdown
              className="main-select-container"
            >
              {showAllEmail
                ? checked?.map((num, index) => (
                    <div key={index} className="select-contain">
                      <div className="" style={{ fontSize: "13px" }}>
                        {num}
                      </div>
                      <div
                        style={{ cursor: "pointer" }}
                        className="close-icon-div"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveFromList(index);
                        }}
                      >
                        <img
                          src={Cancel}
                          style={{ width: "16px", height: "16px" }}
                        />
                      </div>
                    </div>
                  ))
                : checked.slice(0, 2)?.map((num, index) => (
                    <div key={index} className="select-contain">
                      <div className="text-[13px] pr-[6px]">{num}</div>
                      <div
                        className="close-icon-div"
                        onClick={(e) => {
                          e.stopPropagation();
                          handleRemoveFromList(index);
                        }}
                      >
                        <img src={Cancel} className="h-[16px] w-[16px]" />
                      </div>
                    </div>
                  ))}

              {checked.length > 2 && !showAllEmail && (
                // Display "2 more" if there are more than 2 selected emails and the dropdown is not open
                <div
                  className="select-contain"
                  style={{ fontSize: "13px" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    setShowAllEmail(true);
                  }}
                >
                  {checked.length - 2} more
                </div>
              )}
              <div
                className={`toggle-dropdown`}
                style={{
                  transform: isOpenUp ? "rotate(180deg)" : "rotate(360deg)",
                }}
              >
                <img
                  src={Bottom}
                  alt="menuUp"
                  onClick={toggleDropdown} // Use the toggleDropdown function to toggle the dropdown
                  style={{
                    height: "10px",
                    width: "16px",
                    transform: isOpenUp ? "rotate(180deg)" : "rotate(360deg)",
                  }}
                />
              </div>
            </div>
          </div>
        ) : (
          <div onClick={toggleDropdown} style={{ cursor: "pointer" }}>
            <div
              className="select-email-content"
              style={{ border: isError ? "1px solid #FF2626" : "" }}
            >
              <div className="select-placeholder ">Select place</div>
            </div>
            <div
              className={`toggle-dropdown`}
              style={{
                transform: isOpenUp ? "rotate(180deg)" : "rotate(360deg)",
              }}
            >
              <img
                src={Bottom}
                alt="Bottom"
                style={{ height: "10px", width: "16px" }}
              />
            </div>
          </div>
        )}

        {isOpenMenu && (
          <div
            className={`handle-input`}
            style={{
              top: isOpenUp ? "0" : "100%",
              bottom: isOpenUp ? "100%" : "",
            }}
          >
            <div className="Search-email-input">
              <input
                placeholder="Search place"
                className="input-handle-number"
                value={searchText}
                onChange={handleSearchNumber}
              />
              <div className="search-modal">
                <img src={Search} alt="search" className="" />
              </div>
            </div>
            <div className={`handle-check-content`}>
              <div
                className="handle-check-content-inner"
                onClick={(e) => {
                  handleCheckAllChange(e, false);
                }}
              >
                <input
                  type="checkbox"
                  id="myCheck"
                  checked={checked?.length === allOptionsEmail?.length}
                  onChange={(e) => {
                    handleCheckAllChange(e, true);
                  }}
                  className="handle-check-input"
                />
                <div
                  className=""
                  style={{
                    fontWeight: "500",
                    color: " #5F6583",
                    marginLeft: "10px",
                  }}
                >
                  Select all
                </div>
              </div>
              <div className="filtered-email">
                {filteredEmailOptions?.map((cdata, index) => {
                  return (
                    <div
                      key={index}
                      className="filtered-email-inner"
                      onClick={(e) => {
                        handleCountryChange(e, false, cdata.name);
                      }}
                    >
                      <input
                        type="checkbox"
                        id={`myCheck${index}`}
                        checked={checked?.includes(cdata.name)}
                        onChange={(e) => {
                          handleCountryChange(e, true, cdata.name);
                        }}
                        className="handle-check-input"
                      />
                      <div style={{ color: "#5F6583", marginLeft: "10px" }}>
                        {cdata.name}
                      </div>
                    </div>
                  );
                })}
              </div>
              <div
                onClick={handleAddEmail}
                className="handle-check-content-inner"
              >
                <div>
                  <img src={Add} alt="add" />
                </div>
                <div
                  style={{
                    color: "var(--color-primary)",
                    fontSize: "14px",
                    marginLeft: "6px",
                  }}
                >
                  Add new
                </div>
              </div>
            </div>
          </div>
        )}
      </div>
      {/* ADD Email */}

      {isChipEmailModalOpen && (
        <AddEmailChipModal
          isOpen={isChipEmailModalOpen}
          setIsOpen={setIsChipEmailModalOpen}
          checked={checked}
          setChecked={setChecked}
          setAllEmailOptions={setAllEmailOptions}
          newtags={newtags}
          setNewTags={setNewTags}
          AddValuesToList={AddValuesToList}
        />
      )}
    </>
  );
};
export default SelectEmail;
