import React, { useCallback, useEffect, useState } from 'react';
import { GoogleMap, Marker } from '@react-google-maps/api';
import markerIcon from '../../images/CarbonCredits/markerIcon.svg';
import { defaultcenter } from '../../Pages/CarbonCredits/constants';
import { CarbonCreditsViewDataFiltered } from '../../api/carbonCredits/endpoints';
const containerStyle = {
  width: '100%',
  height: '380px',
};
function GoogleMapsComp(props) {
  const { initialMP } = props;

  return (
    props.isLoaded && (
      <GoogleMap
        id="google-map"
        mapContainerStyle={containerStyle}
        center={props.center || defaultcenter}
        zoom={3}
        options={props.mapOptions}
      >
        {/* Child components, such as markers, info windows, etc. */}
        {props.markerPoint !== null && (
          <Marker
            position={{
              lat: props.markerPoint.lat,
              lng: props.markerPoint.lng,
            }}
            zIndex={2}
          />
        )}
        {props.farmLocationMarkers &&
          props.farmLocationMarkers.length > 0 &&
          props.farmLocationMarkers.map((marker, index) => (
            <>
              <Marker
                key={index}
                position={{ lat: marker.lat, lng: marker.lng }}
                icon={{
                  url: markerIcon,
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                zIndex={parseInt(`-${index}`, 10)}
              />
            </>
          ))}
        {initialMP &&
          initialMP.length > 0 &&
          initialMP.map((MP, index) => (
            <>
              <Marker
                key={index}
                position={{ lat: MP.lat, lng: MP.lng }}
                icon={{
                  url: markerIcon,
                  scaledSize: new window.google.maps.Size(40, 40),
                }}
                zIndex={parseInt(`-${index}`, 10)}
              />
            </>
          ))}
      </GoogleMap>
    )
  );
}
export default GoogleMapsComp;
