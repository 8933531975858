import React, { useRef, useState } from "react";
import {
  InputBox,
  InputTextArea,
} from "../../Components/InputFields/InputField";
import PhoneInput from "react-phone-number-input";
import SelectEmail from "../InputTag/SelectEmail";
import "./FormComponent.css";
import "react-phone-number-input/style.css";

import { get_milk_sold_to_list } from "../../Pages/FarmForm/constants";


const FormComponent = ({
  heading,
  subHeading,
  list,
  handleChange,
  isBackground,
  disable,
}) => {
  const [selectedValues, setSelectedValues] = useState([]);
  const [milkSoldList, setMilkSoldList] = useState(get_milk_sold_to_list());
  return (
    <>
      <p className="form-subHeading">{heading}</p>
      {subHeading && <p>{subHeading}</p>}
      <div
        className="form-fields"
        style={{
          padding: isBackground ? "20px" : "",
          background: isBackground ? "#E6E6E6" : "",
          borderRadius: isBackground ? "10px" : "",
        }}
      >
        {list.map((farmInfo, index1) => {
          return (
            <React.Fragment key={index1 + 1}>
              {farmInfo.type === "text" || farmInfo.type === "date" ? (
                <>
                  <div className="form-field">
                    <InputBox
                      label={farmInfo.label}
                      type={farmInfo.type}
                      handleChange={handleChange}
                      value={farmInfo.value}
                      isError={farmInfo.error}
                      disable={disable}
                      notRequired={farmInfo.notRequired}
                      max={farmInfo.max}
                      min={farmInfo.min}
                    />
                    {(farmInfo?.message && !farmInfo?.isError) && <span style={{fontWeight:"600",color:"#fabb00"}}>{farmInfo.message}</span>}
                    {(farmInfo?.message && farmInfo?.isError) && <span style={{fontWeight:"600",color:"#FF0000"}}>{farmInfo.message}</span>}
                    </div>
                </>
              ) : (
                <>
                  {farmInfo.type === "phone" ? (
                    <>
                      <div className="form-field">
                        <div className="label">
                          {farmInfo.label}
                          {farmInfo.notRequired ? (
                            ""
                          ) : (
                            <span className="required">*</span>
                          )}
                        </div>
                        {disable ? (
                          <>
                            <div className="phone-input-container">
                              {/* <div className="flag-container">
                                <img
                                  style={{ width: "30px", height: "20px" }}
                                  src={
                                    farmInfo.state
                                      ? `https://purecatamphetamine.github.io/country-flag-icons/3x2/${farmInfo.state}.svg`
                                      : `https://purecatamphetamine.github.io/country-flag-icons/3x2/US.svg`
                                  }
                                  alt=""
                                />
                              </div> */}
                              <input
                                type="text"
                                value={farmInfo.value}
                                disabled={disable}
                              />
                            </div>
                          </>
                        ) : (
                          <PhoneInput
                            name=""
                            disabled={disable}
                            // international
                            limitMaxLength
                            style={{
                              border: farmInfo.error
                                ? "1px solid #FF2626"
                                : `1px solid #e6e6fc`,
                              marginTop: "6px",
                              height: "50px",
                              borderRadius: "6px",
                              padding: "0px 10px",
                            }}
                            // defaultCountry={"US"}
                            // countryCallingCodeEditable={disable}
                            value={farmInfo.value}
                            onChange={(value) => {
                              handleChange(
                                farmInfo.label,
                                value,
                                farmInfo.type
                              );
                            }}
                          />
                        )}
                      </div>
                    </>
                  ) : (
                    <>
                      {farmInfo.type === "dropdown-tag" ? (
                        <>
                          <div className="field">
                            <div className="input-box">
                              <div className="label">
                                {farmInfo.label}
                                {farmInfo.notRequired ? (<></>) : (<span className="required">*</span>)}
                              </div>
                              <div>
                                <SelectEmail
                                  checked={selectedValues}
                                  setChecked={setSelectedValues}
                                  allOptionsEmail={milkSoldList || []}
                                  setMilkSoldList={setMilkSoldList}
                                />
                              </div>
                            </div>
                          </div>
                        </>
                      ) : (
                        <>
                          <div className="form-field">
                            <InputTextArea
                              label={farmInfo.label}
                              height="120px"
                              type={farmInfo.type}
                              value={farmInfo.value}
                              handleChange={handleChange}
                              disable={disable}
                            />
                          </div>
                        </>
                      )}
                    </>
                  )}
                </>
              )}
            </React.Fragment>
          );
        })}
      </div>
      <div className="bottom-border-line"></div>
    </>
  );
};

export default FormComponent;
