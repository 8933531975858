import axiosAuthMiddleware from '../middleware/middleware';

export const GetCarbonCreditsFiltersDropdowns = async (id) => {
    const url = `/get_carbon_credits_filters_dropdowns?project_id=${id}`;
    const res = axiosAuthMiddleware.request({
        url: url,
        method: 'GET',
        headers: {
            'Content-Type': 'text/plain',
        },
    });
    return await res;
}

 export const CarbonCreditsViewDataFiltered = async(data) =>{
    const url = `/carbon_credits_view_data_filtered`
    const res = axiosAuthMiddleware.request({
        url: url,
        method: 'POST',
        headers: {
            'Content-Type': 'text/plain',
        },
        data:data
    });
    return await res;
}