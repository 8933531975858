import React, { useEffect, useRef, useState, useCallback } from 'react';
import { useJsApiLoader } from '@react-google-maps/api';
import {
	InputAutoAddress,
	InputBoxWithDropdown,
} from '../../Components/InputFields/InputField';
import './CarbonCredits.css';
import GoogleMapsComp from '../../Components/GoogleMapsComponent/GoogleMapsComp';
import {
	CarbonCreditsViewDataFiltered,
	GetCarbonCreditsFiltersDropdowns,
} from '../../api/carbonCredits/endpoints';
import { useSelector } from 'react-redux';
import {
	defaultcenter,
	mapOptions,
	DistanceList,
} from './constants';
import MultipleSelect from '../../Components/InputTag/MultipleSelect';
import FarmImg from '../../images/Analytics/FarmImg.svg';
import CowImg from '../../images/Analytics/CowImg.svg';
import CarbonImg from '../../images/Analytics/Co2.svg';
import Loader from '../../Components/Loader/loader';

const libraries = ['places'];

const CarbonCredits = () => {
	const inputRef = useRef();
	const { selectedProject } = useSelector((state) => state.project);
	const { isLoaded: mapLoader } = useJsApiLoader({
		libraries,
		options: mapOptions,
		googleMapsApiKey: process.env.REACT_APP_GOOGLE_API,
	});

	const [isLoading, setIsLoading] = useState(true);

	// Address Filter
	const [center, setCenter] = useState(defaultcenter); // Center of the map

	const [farmLocationMarkers, setFarmLocationMarkers] = useState([]);

	const [MilkSoldToValue, setMilkSoldToValue] = useState([]); // Selected filters
	const [statusValue, setStatusValue] = useState([]);
	const [farmStateValue, setFarmStateValue] = useState([]);


	const initialFilterData = {
		latitude: null,
		longitude: null,
		project_id: null,
		distance: null,
		milk_sold_to: null,
		farm_status: null,
		farm_state: null,
	};	  
	const [data, setData] = useState(initialFilterData);

	const initialFormDetails = [
		{
			key: 0,
			label: 'Address',
			type: 'text',
			value: '',
			id: 'address',
			name: 'address',
			error: false,
			notRequired: true,
		},
		{
			key: 1,
			label: 'Distance',
			type: 'dropdown',
			value: '500 miles',
			error: false,
			list: DistanceList,
			id: 'distance',
			disabled: false,
			notRequired: true,
		},
		{
			key: 2,
			label: 'Milk Sold to',
			type: 'dropdown-tag',
			values: MilkSoldToValue,
			setValue: setMilkSoldToValue,
			error: false,
			list: [],
			id: 'milk_sold_to',
			disabled: false,
			notRequired: true,
		},
		{
			key: 3,
			label: 'Farm State/Province',
			type: 'dropdown-tag',
			error: false,
			values: farmStateValue,
			setValue: setFarmStateValue,
			list: [],
			id: 'farm_state',
			disabled: false,
			notRequired: true,
		},
		{
			key: 4,
			label: 'Farm Status',
			type: 'dropdown-tag',
			values: statusValue,
			setValue: setStatusValue,
			error: false,
			list: [],
			id: 'farm_status',
			disabled: false,
			notRequired: true,
		},
	];
	const [formDetails, setFormDetails] = useState(initialFormDetails);

	const initialCardDetails = [
		{
			id: 1,
			title: 'Total Farms in the Selection',
			count: null,
			image: FarmImg,
		},
		{
			id: 2,
			title: 'Total Cows',
			count: null,
			image: CowImg,
		},
		{
			id: 3,
			title: 'Total Carbon Credits in the Selection',
			count: null,
			image: CarbonImg,
		},
	];
	const [cardDetails, setCardDetails] = useState(initialCardDetails);

	const [initialMP, setInitialMP] = useState();
	const [updated, setUpdated] = useState(false);
	const [carboncreditdata, setCarboncreditdata] = useState();

	const fetchData = useCallback(async () => {
		try {
			const resDropdownOptions = await GetCarbonCreditsFiltersDropdowns(selectedProject.id);
			const filteredData = Object.fromEntries(
				Object.entries(data).filter(([_, value]) => value !== null)
			);
			filteredData.project_id = selectedProject.id;
			const carboncredit = await CarbonCreditsViewDataFiltered(filteredData);

			if (resDropdownOptions.status === 200 && carboncredit.status === 200) {
				const { milk_sold_to_options: resMilk, status_options: resStatus, farm_state_options: resState } = resDropdownOptions.data;
				const milkData = resMilk.map((value, index) => ({ id: index + 1, name: value }));
				const statusData = resStatus.map((value, index) => ({ id: index + 1, name: value }));
				const stateData = resState.map((value, index) => ({ id: index + 1, name: value }));

				setCarboncreditdata(carboncredit.data.carbon_credit);
				setFarmLocationMarkers(carboncredit.data.farm_locations);
				setCardDetails(cardDetails.map(dets => {
					if (dets.id === 1) return { ...dets, count: carboncredit.data.total_farms };
					if (dets.id === 2) return { ...dets, count: carboncredit.data.total_cows };
					return { ...dets, count: carboncredit.data.total_credits };
				}));
				setInitialMP(carboncredit.data.farm_locations)

				const updated = formDetails.map((details) => {
					switch (details.id) {
						case 'farm_status': // Corrected to match the id in formDetails
							return { ...details, list: statusData, isError: false };
						case 'milk_sold_to': // Corrected to match the id in formDetails
							return { ...details, list: milkData, isError: false };
						case 'farm_state': // Corrected to match the id in formDetails
							return { ...details, list: stateData, isError: false };
						default:
							return details;
					}
				});
				setFormDetails(updated);
				setIsLoading(false);
			}
		} catch (error) {
			console.error('Error fetching data:', error);
		}
	}, [data, selectedProject, cardDetails, formDetails]);

	const handleClear = () => {
		setCenter(defaultcenter);
		setFormDetails(initialFormDetails);
		setCardDetails(initialCardDetails);
		setData(initialFilterData);
	};

	const handleValueChange = useCallback((label, value) => {
		setFormDetails(formDetails.map(dets => dets.label === label ? { ...dets, value } : dets));
	}, [formDetails]);

	const handleAddressChange = useCallback(() => {
		const [place] = inputRef.current.getPlaces();
		if (place) {
			setFormDetails(formDetails.map(dets => dets.label === 'Address' ? { ...dets, value: place.name } : dets));
			const { lat, lng } = place.geometry.location;
			setCenter({ lat: lat(), lng: lng() });
			setData(prevData => ({
				...prevData,
				latitude: lat(),
				longitude: lng(),
				distance: parseInt(formDetails[1].value),
			}));
			setUpdated(!updated);
		}
	}, [formDetails, updated]);

	const handleSelect = useCallback((label, value) => {
		setFormDetails(formDetails.map(data => {
			if (data.label === label) {
				data.id === 'distance'
					? setData(prevData => ({ ...prevData, [data.id]: parseInt(value) }))
					: setData(prevData => ({ ...prevData, [data.id]: value }));
				return { ...data, isError: false, value };
			}
			return data;
		}));
		setUpdated(!updated);
	}, [formDetails, updated]);

	const handleMultiSelect = useCallback((data, label) => {
		setFormDetails(formDetails.map(details => {
			if (details.label === label) {
				setData(prevData => ({ ...prevData, [details.id]: data }));
				return { ...details, values: data };
			}
			return details;
		}));
	}, [formDetails]);

	useEffect(() => {
		if (selectedProject !== null) {
			fetchData();
		}
	}, [selectedProject,data]);

	return isLoading ? (<Loader />) : (
		<div className="carbon-container">
			<div className="fileds">
				<div className="Filter-field">
					{formDetails.map((details, index) =>
						details.type === 'dropdown' ? (
							<InputBoxWithDropdown
								key={index}
								list={details.list}
								label={details.label}
								value={details.value}
								isError={details.error}
								disable={details.disabled}
								actualIndex={details.key}
								outerindex={index}
								notRequired={details.notRequired}
								onSelect={handleSelect}
							/>
						) : (
							details.type === 'text' && (
								<InputAutoAddress
									key={index}
									label={details.label}
									value={details.value}
									type={details.type}
									isError={details.error}
									handlePlaceChanged={handleAddressChange}
									notRequired={details.notRequired}
									handleValueChange={handleValueChange}
									isLoaded={mapLoader}
									inputRef={inputRef}
								/>
							)
						)
					)}
					<div className="clear-button" onClick={handleClear}>
						Clear
					</div>
				</div>
				<div className="sub-fields">
					{formDetails.map(
						(details, index) => details.type === 'dropdown-tag' && (
							<div className="field-dropdown-tag" key={index}>
								<div className="input-box">
									<div className="label">{details.label}</div>
									<MultipleSelect
										checked={details.values}
										handleMultiSelect={handleMultiSelect}
										allOptionsEmail={details.list || []}
										isError={details.error}
										showAddButton={false}
										isFilter={true}
										label={details.label}
										setUpdated={setUpdated}
										updated={updated}
									/>
								</div>
							</div>
						)
					)}
				</div>
			</div>
			<div className="geo-map">
				<GoogleMapsComp
					center={center}
					markerPoint={center}
					isLoaded={mapLoader}
					mapOptions={mapOptions}
					initialMP={initialMP}
					farmLocationMarkers={farmLocationMarkers}
				/>
			</div>
			<div className="carbon-cards">
				{cardDetails.map((cardInfo, index) => (
					<div className="custome-card" key={index}>
						<div className="card-details">
							<div className="card-name">{cardInfo.title}</div>
							<div className="card-count">
								{cardInfo.count === null ? 0 : cardInfo.count}
							</div>
						</div>
						<img src={cardInfo.image} alt="" />
					</div>
				))}
			</div>
			<div className="table-container-analytics">
				<div className="table-body-analytics">
					<div className="table-header" style={{ paddingTop: '14px', paddingLeft: '30px', paddingRight: '30px' }}>
						<div className="facilator-head-analytics">Milk Sold To</div>
						<div className="farm-head-analytics">Credits</div>
					</div>
					<div className="border-bottom-analytics"></div>
					{carboncreditdata && Object.keys(carboncreditdata).reverse().map((key, index1) => (
						<div className="table-row-analytics" key={index1}>
							<div className="facilator-head-analytics">{key}</div>
							<div className="farm-head-analytics">{carboncreditdata[key]}</div>
						</div>
					))}
				</div>
			</div>
		</div>
	);
};

export default CarbonCredits;
