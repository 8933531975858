import React, { useState, useCallback, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import './Modals.css';
import { InputTextArea } from '../InputFields/InputField';
import { Button } from '../Button/Button';
import { rejectFarm } from '../../api/Farm/endpoints';
import { toastFailure, toastSuccess } from '../Toaster/Toast';

const RejectModal = ({ isRejectModalOpen, setIsRejectModalOpen, uniqueId, setFarmFormStatus }) => {
  const [rejectReasonValue, setRejectReasonValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const handleChange = useCallback((label, e) => {
    setRejectReasonValue(e.target.value);
  }, []);

  const handleSend = useCallback(async () => {
    setIsLoading(true);
    if (rejectReasonValue.trim() === "") {
      toastFailure("Please write a reason");
      setIsLoading(false);
      return;
    }

    const data = {
      reason: rejectReasonValue,
      unique_key: uniqueId,
    };

    try {
      const responseEmail = await rejectFarm(data);
      if (responseEmail.status === 200 || responseEmail.status === 201) {
        toastSuccess(responseEmail.data.message);
        setFarmFormStatus(responseEmail.data.form_status);
        setIsRejectModalOpen(false);
      } else {
        toastFailure(responseEmail.data);
      }
    } catch (error) {
      toastFailure("An error occurred");
    } finally {
      setIsLoading(false);
    }
  }, [rejectReasonValue, uniqueId, setFarmFormStatus, setIsRejectModalOpen]);

  useEffect(() => {
    if (!isRejectModalOpen) {
      setRejectReasonValue('');
    }
  }, [isRejectModalOpen]);

  return (
    <Modal
      show={isRejectModalOpen}
      onHide={() => setIsRejectModalOpen(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="reject-modal"
    >
      <Modal.Body>
        <div className="custom-modal-content" style={{ padding: "12px 20px" }}>
          <div className="reject-title-cls">Request revision to the Farm Form</div>
          <div >This will send the farmer an email requesting revision to the Farm Form submission and include the message you enter below.</div>
          <div className="reject-body-content">
            <div className="form-field" style={{ width: '100%' }}>
              <InputTextArea
                label="Reason"
                height="120px"
                type="address"
                value={rejectReasonValue}
                handleChange={handleChange}
              />
            </div>
            <div className="buttons-reject">
              <Button
                text="Send"
                width="160px"
                handleClick={handleSend}
                isLoading={isLoading}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default RejectModal;
