import React, { useState, useEffect } from 'react';
import Modal from 'react-bootstrap/Modal';
import './Modals.css';
import { Button } from '../Button/Button';

const ApproveModal = ({ isApproveModalOpen, setIsApproveModalOpen, submitFunction, runReceiptInfoValidation}) => {
  const [checkboxes, setCheckboxes] = useState([false, false, false, false]);
  const [plausilibityCheckError, setPlausibilityCheckError] = useState(false);

  console.log('PLAUS ERROR', plausilibityCheckError);
  useEffect(() => {
    console.log('<MODAL CHNGE', isApproveModalOpen);
    if (!isApproveModalOpen) {
      setCheckboxes([false, false, false, false]);
    } else {
      console.log('RUNNING PLAUSI CHECK');
      setPlausibilityCheckError(runReceiptInfoValidation());
    }
  }, [isApproveModalOpen]);

  const handleCheckboxChange = (index) => {
    setCheckboxes((prev) => {
      const newCheckboxes = [...prev];
      newCheckboxes[index] = !newCheckboxes[index];
      return newCheckboxes;
    });
  };

  const allChecked = checkboxes.every(Boolean) && (plausilibityCheckError === false);

  return (
    <Modal
      show={isApproveModalOpen}
      onHide={() => setIsApproveModalOpen(false)}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      className="reject-modal"
    >
      <Modal.Body>
        <div className="custom-modal-content" style={{ padding: "12px 20px" }}>
          <div className="reject-title-cls">Approve the Farm Form</div>
          <div>Clicking approve below will officially submit and lock the Farm Data. Please review the data and files before approving.</div>
          <div className="reject-body-content">

            <div className="form-field" style={{ width: '100%' }}>
              {plausilibityCheckError && 
                <div className="error-msg">Data Validation Check failed: Please review the receipt and farm animal data data</div>
              }
              <br />
              {[
                "I have ensured the farm has uploaded all primary data as required",
                "I have reviewed that Uploaded primary data matches 'Animal Data' entered in the form",
                "I have correctly entered the Agolin purchase data based on the receipts after reviewing the uploaded Agolin purchase receipts",
                "I have reviewed the data with the Farmer"
              ].map((text, index) => (
                <div key={index}>
                  <input
                    type="checkbox"
                    checked={checkboxes[index]}
                    onChange={() => handleCheckboxChange(index)}
                    className="handle-check-input"
                  />&ensp;{text}
                </div>
              ))}
            </div>
            <div className="buttons-reject">
              <Button
                text="Approve"
                width="160px"
                handleClick={() => {
                  submitFunction('approve');
                  setIsApproveModalOpen(false);
                }}
                disabled={!allChecked}
              />
            </div>
          </div>
        </div>
      </Modal.Body>
    </Modal>
  );
};

export default ApproveModal;
