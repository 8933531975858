import React from 'react';
import './Button.css';
import LeftA from "../../images/Facilitator/LeftA.png";
import RightA from "../../images/Facilitator/RightA.png";
import Loader from '../Loader/loader';

const ButtonBase = ({
  maxWidth,
  width,
  bgColor,
  textColor,
  handleClick,
  isLoading,
  disabled,
  children,
  additionalStyles = {},
  additionalClasses = ''
}) => (
  <div
    style={{
      width: width || '100%',
      maxWidth: maxWidth || '',
      backgroundColor: disabled ? 'gray' : (bgColor || 'var(--color-primary)'),
      cursor: disabled ? 'not-allowed' : 'pointer',
      ...additionalStyles,
    }}
    className={`button ${additionalClasses} ${disabled ? 'button-disabled' : ''}`}
    onClick={() => {
      if (!isLoading && !disabled) {
        handleClick();
      }
    }}
  >
    {isLoading ? (
      <Loader
        height="25px"
        width="25px"
        color="#ffffff"
        radius="1"
        strokeWidth={5}
      />
    ) : (
      children
    )}
  </div>
);

export const Button = ({
  maxWidth,
  width,
  bgColor,
  text,
  textColor,
  handleClick,
  isLoading,
  disabled,
}) => (
  <ButtonBase
    maxWidth={maxWidth}
    width={width}
    bgColor={bgColor}
    textColor={textColor}
    handleClick={handleClick}
    isLoading={isLoading}
    disabled={disabled}
  >
    <div style={{ color: textColor || 'white' }}>
      {text}
    </div>
  </ButtonBase>
);

export const ButtonWithArrow = ({
  maxWidth,
  width,
  bgColor,
  text,
  textColor,
  handleClick,
  isLoading,
  disabled,
  reverse,
}) => (
  <ButtonBase
    maxWidth={maxWidth}
    width={width}
    bgColor={bgColor}
    textColor={textColor}
    handleClick={handleClick}
    isLoading={isLoading}
    disabled={disabled}
    additionalStyles={{ flexDirection: reverse ? 'row-reverse' : 'row' }}
    additionalClasses="button-arrow"
  >
    <div>
      <img src={reverse ? RightA : LeftA} alt="" />
    </div>
    <div style={{ color: textColor || 'white' }}>
      {text}
    </div>
  </ButtonBase>
);
